import { Container, Loader, Typography } from '@pwskills/rachnaui';
import React from 'react';

const Spinner = () => {
  return (
    <Container>
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[9999999] overflow-hidden bg-darkBlack opacity-75 flex flex-col items-center justify-center">
        <Loader />
        <Typography
          component="body-regular"
          variant="light"
          className="text-center text-offWhite mt-6"
        >
          This may take a few seconds, please don&apos;t close this page.
        </Typography>
      </div>
    </Container>
  );
};

export default Spinner;
