/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */

export enum DialogItemEnum {
  Submit = 'submit',
  LeaveQuiz = 'leaveQuiz',
  TestOver = 'testOver',
  Report = 'report',
  FullScreenExit = 'fullScreenExit',
  Reload = 'reload',
  Focus = 'focus',
  FeedbackSuccess = 'feedbackSuccess',
  FeedbackError = 'feedbackError',
}

export type DialogItemType = DialogItemEnum | null;

export enum ReattemptDialogType {
  Reattempt = 'reattempt',
  TimeCheck = 'timeCheck',
}

export enum ToastEnum {
  success = 'success',
  info = 'info',
  warning = 'warning',
  error = 'error',
}

export enum SolutionStatus {
  Correct = 'Correct',
  Incorrect = 'Incorrect',
  PartiallyCorrect = 'Partially Correct',
  NotAttempted = 'Not Attempted',
}

export enum ComparisonFactorForComparativeAnalysis {
  SCORE = 'score',
  ACCURACY = 'accuracy',
  COMPLETION = 'completion',
  TIME_TAKEN = 'timeTaken',
}

export enum GraphTypeEnum {
  PERFORMANCE_ANALYSIS = 'performance_analysis',
  COMPARATIVE_ANALYSIS = 'comparative_analysis',
}
