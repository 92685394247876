/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { kaptureBaseAPIUrl } from '@/utils/common/env.constants';
import axios from 'axios';

export const callKaptureAPI = async (payload: {
  contact_person_phone: string;
  contact_person_email: string | undefined;
  customer_code: string;
  contact_person_name: string;
}) => {
  await axios.post(`${kaptureBaseAPIUrl}/add-update-enquiry`, payload);
};
