/* eslint-disable no-unsafe-optional-chaining */
import userCoursesApi from '@/api/userCourses';
import useUserCourseDetails, { UsersingleCourseDetails } from '@/store/userCoursesDetails';

export default function useGetUserAllCourses() {
  const { setUserCourses, setUserCoursesMap, setPlanCourseMap } = useUserCourseDetails();
  const mapUserAllCoursesFunction = (courseArray: UsersingleCourseDetails[]) => {
    let tempObj = {};
    let tempPlanCourseMap = {};
    courseArray?.forEach((singleCourse: UsersingleCourseDetails) => {
      tempObj = {
        ...tempObj,
        [singleCourse?.courseId]: {
          recentlyAccesedLesson:
            singleCourse?.recentlyAccessedLessons?.[
              singleCourse?.recentlyAccessedLessons?.length - 1
            ]?.lessonId,
          parentCourse: singleCourse?.parentCourse || {},
          courseSlug: singleCourse?.courseSlug || '',
          purchasedCourseType: singleCourse?.courseType || '',
        },
      };
      // to handle plan courses
      if (singleCourse?.parentCourse) {
        tempPlanCourseMap = {
          ...tempPlanCourseMap,
          [singleCourse?.parentCourse?._id]: {
            courseSlug: singleCourse?.courseSlug,
            childCourse: singleCourse?.courseId,
          },
        };
      }
    });
    return { tempObj, tempPlanCourseMap };
  };

  const getUserCoursesFunction = async () => {
    try {
      const response: any = await userCoursesApi.getUserCourses();
      const mappedCourses = mapUserAllCoursesFunction(response?.data);
      setUserCoursesMap(mappedCourses?.tempObj);
      setPlanCourseMap(mappedCourses?.tempPlanCourseMap);
      setUserCourses(response?.data);
    } catch {
      //
    }
  };
  return {
    getUserCoursesFunction,
  };
}
