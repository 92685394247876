interface IObj {
  [key: string]: string;
}

const useCouponCode = () => {
  if (typeof window !== 'undefined') {
    const checkQueryParam = ['campaign', 'coupon_code'];
    const queryParams = new URLSearchParams(window.location.search);
    const paramsObj = Object.fromEntries(queryParams.entries());
    const obj: IObj = {};

    Object.keys(paramsObj).map((key) => {
      if (checkQueryParam.includes(key)) {
        obj[key] = paramsObj[key];
      }
      return true;
    });

    const couponQuery = JSON.parse(localStorage.getItem('coupon_data') || '{}');

    return couponQuery;
  }
  return {};
};

export default useCouponCode;
