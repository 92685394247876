/* eslint-disable @typescript-eslint/indent */

import React from 'react';
import { SWRConfig } from 'swr';
import { BareFetcher, ProviderConfiguration, PublicConfiguration } from 'swr/_internal';

interface SwrConfigProviderProps {
  children: React.ReactNode;
}

const swrConfig: Partial<PublicConfiguration<any, any, BareFetcher<any>>> &
  Partial<ProviderConfiguration> = {
  shouldRetryOnError: false,
  revalidateOnFocus: false,
  // revalidateOnFocus: true,
  // revalidateIfStale: true,
  // revalidateOnMount: true,
  // dedupingInterval: 10000,
};

const SwrConfigProvider = ({ children }: SwrConfigProviderProps) => {
  return <SWRConfig value={swrConfig}>{children}</SWRConfig>;
};

export default SwrConfigProvider;
