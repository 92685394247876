import { Facebook, Linkedin, Twitter } from '@pwskills/rachnaui/Icons';
import {
  ComparisonFactorForComparativeAnalysis,
  DialogItemEnum,
  ReattemptDialogType,
} from '@/interfaces/testQuiz.enum';
import WhatsApp from '@/components/common/Icons/socialMedia/WhatsApp';
import Reddit from '@/components/common/Icons/socialMedia/Reddit';
import Telegram from '@/components/common/Icons/socialMedia/Telegram';

export const shareMedia = [
  {
    className: 'bg-[#D6F5E6]',
    icon: <WhatsApp />,
    text: 'WhatsApp',
  },
  {
    className: 'bg-[#DCE7FF]',
    icon: <Facebook className="text-[#3C5A9A]" />,
    text: 'Facebook',
  },
  {
    className: 'bg-[#DFF4FF]',
    icon: <Twitter className="text-[#03A9F4]" />,
    text: 'Twitter',
  },
  {
    className: 'bg-[#EAF8FF]',
    icon: <Telegram className="w-20 h-20" />,
    text: 'Telegram',
  },
  {
    className: 'bg-[#FFF3EE]',
    icon: <Reddit className="w-6 h-6 m-1 text-gray-300" />,
    text: 'Reddit',
  },
  {
    className: 'bg-[#DEF3FE]',
    icon: <Linkedin className="text-[#0077B5]" />,
    text: 'Linkedin',
  },
];

const QUESTION_REPORT_OPTIONS = [
  { label: 'Question is not correct', value: 'Question is not correct' },
  { label: 'Correct option not available', value: 'Correct option not available' },
  { label: 'Answer is not mapped correctly', value: 'Answer is not mapped correctly 2' },
  { label: 'Image not clear', value: 'Image not clear' },
  { label: 'Other', value: 'Other' },
];

const SOLUTION_REPORT_OPTIONS = [
  { label: 'Wrong concept covered in video', value: 'Wrong concept covered in video' },
  { label: 'Not explained properly', value: 'Not explained properly' },
  { label: 'Not taken enough example to explain', value: 'Not taken enough example to explain 2' },
];

const REPORT_TEST_SUCCESS_MESSAGE =
  'We have received your feedback. You can report any new issue after 24 hours. ';

const TIME_SYNC_ERROR_MESSAGE =
  'Invalid System Time. Please check your system time. Refresh the page to try again';

const resultsHeaderDialogTitle = {
  [ReattemptDialogType.Reattempt]: 'Reattempt Test',
  [ReattemptDialogType.TimeCheck]: 'Preparing your Test',
  [`${ReattemptDialogType.TimeCheck}-Error`]: 'Error',
};

const resultsHeaderDialogSubtitle = {
  [ReattemptDialogType.Reattempt]: 'Are you sure you want to reattempt?',
  [ReattemptDialogType.TimeCheck]: 'Please wait while we prepare competitive test for you',
};

export const ErrorMessages = {
  FULLSCREEN: 'Unable to transition to Full Screen',
  SUBMITTEST: 'Unable to Submit test. Please try to submit again..',
  SUBMITQUIZ: 'Unable to Submit quiz. Please try to submit again..',
  SUBMITQUIZANSWER: 'Unable to Submit your answer. Please try to submit again..',
  UNEXPECTED_ERROR: 'An unexpected error has occured. Please try again after some time.',
  ANSWER_CHOOSE: 'Unable to process your answer',
  CHANGE_STATUS: 'Unable to change review status',
  GENERIC_FETCH_ERROR: 'Unable to fetch data. Please try after some time',
  NOQUESTIONANSWERED: 'You need to select at least one answer in order to submit the question',
};

export const ComparativeGraphOptions: ComparisonFactorForComparativeAnalysis[] = [
  ComparisonFactorForComparativeAnalysis.SCORE,
  ComparisonFactorForComparativeAnalysis.ACCURACY,
  ComparisonFactorForComparativeAnalysis.COMPLETION,
  ComparisonFactorForComparativeAnalysis.TIME_TAKEN,
];

export const dialogTitleMap: Record<DialogItemEnum, string> = {
  [DialogItemEnum.Report]: 'Please tell us about the issue',
  [DialogItemEnum.LeaveQuiz]: 'Leave Quiz',
  [DialogItemEnum.TestOver]: 'Time Up!!',
  [DialogItemEnum.FullScreenExit]: 'Warning!!',
  [DialogItemEnum.Focus]: 'Warning!!',
  [DialogItemEnum.Reload]: 'Warning!!',
  [DialogItemEnum.FeedbackSuccess]: 'Thank you for your feedback',
  [DialogItemEnum.FeedbackError]: 'Already Reported',
  [DialogItemEnum.Submit]: 'Are you sure you want to submit?',
};

export const END_TIME = '00:00:00';

export const MOBILE_VIEWPORT = 1024;
export const tabWidth = 640;

export {
  QUESTION_REPORT_OPTIONS,
  SOLUTION_REPORT_OPTIONS,
  REPORT_TEST_SUCCESS_MESSAGE,
  TIME_SYNC_ERROR_MESSAGE,
  resultsHeaderDialogTitle,
  resultsHeaderDialogSubtitle,
};

export const ACCESS_TOKEN = '76hgg6s2321';
export const USER_ID = 'user_id';
export const USER_COUNTRY = 'IN';
export const PP_TOKEN = '96drm8s112';

export const NAME_VALIDATOR =
  /^[ a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ'`'-]+$/;

export const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// need to remove these if not needed
export const checkoutContextRoutes = ['/checkout', '/transaction'];

export const nonNotificationRoutes = ['learn', '404', 'checkout', 'transaction'];

export const REGISTRATION_STATUS = {
  OPEN: 'Registrations Started',
  BEGIN_SOON: 'Registrations will begin Soon',
  CLOSED: 'Registrations Closed',
};

export const DEGREE_ADMISSION_STATUS = {
  OPEN: 'Admission Open',
  BEGIN_SOON: 'Admission opening Soon',
  CLOSING_SOON: 'Admission closing Soon',
  CLOSED: 'Admission Closed',
};

export const COURSE_PURCHASE_TYPE = {
  PLAN: 'plan',
  INDIVIDUAL: 'individual',
};

export const tawkPageRoutes = [
  '/',
  '/courses',
  '/course/[courseSlug]',
  '/checkout',
  '/transaction',
  '/about-us',
  '/contact-us',
  '/faqs',
  '/job-assistance',
  '/privacy-policy',
  '/terms-and-conditions',
];
// need to remove  - till here

export const QUERY_PARAMS_TO_REMOVE = ['attemptNumber', 'analytics', 'leaderboard'];

export const CourseTypes = {
  PLACEMENT: 'placement',
  REGULAR: 'regular',
  CAMPUS_EDGE: 'campus_edge',
};

export const CARD_TYPE = {
  NORMAL_COURSE: 'normal_courses',
  ONLINE_DEGREE_COURSE: 'online_degree_courses',
};
export const quizStatusArray = ['correct', 'incorrect', 'partially_correct'];

export const FAQ_TYPES = {
  DEFAULT: 'default',
  ACCORDION: 'accordion',
};

export const PAGE_FROM_ROUTES = {
  COURSE_LISTING_PAGE: 'course_listing',
  CATEGORY_PAGE: 'category_page',
  LEARN_PAGE: 'learn_page',
  MASTERCLASS_DESCRIPTION_PAGE: 'masterclass_description_page',
};
