import { IUserDetails } from '@/interfaces/common';

const MOENGAGE_SCRIPT_URL = 'https://cdn.moengage.com/release/dc_3/moe_webSdk.min.latest.js';

export const initialiseMoengage = () => {
  return new Promise((resolve) => {
    if (!document.querySelector(`script[src="${MOENGAGE_SCRIPT_URL}"]`)) {
      const scriptElement = document.createElement('script');
      scriptElement.src = MOENGAGE_SCRIPT_URL;

      scriptElement.addEventListener('load', () => {
        (window as any).moe({
          app_id: `${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}`,
          debug_logs: `${process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod' ? 0 : 1}`,
          cluster: 'DC_3',
          disable_onsite: false,
        });
        resolve('success');
      });
      document.head.appendChild(scriptElement);
    }
  });
};

export const addMoEngageUser = (userDetails: IUserDetails) => {
  return new Promise((resolve) => {
    if (typeof window !== 'undefined' && (window as any).Moengage) {
      (window as any).Moengage.add_unique_user_id(userDetails?.data?._id).then(() => {
        (window as any).Moengage.add_user_name(
          `${userDetails?.data.firstName} ${userDetails?.data.lastName}`
        );
        (window as any).Moengage.add_first_name(`${userDetails?.data.firstName}`);
        (window as any).Moengage.add_last_name(`${userDetails?.data.lastName}`);
        if (userDetails?.data.verified) {
          (window as any).Moengage.add_email(userDetails?.data?.email);
        }
        (window as any).Moengage.add_mobile(userDetails?.data?.phoneNumber);
      });
      resolve('resolved');
    }
  });
};
