const baseApiUrl = process.env.NEXT_PUBLIC_BASE_API_URL || '';
const s3Url = process.env.NEXT_PUBLIC_S3_PREFIX || '';
const certificateUrl = process.env.NEXT_PUBLIC_CERTIFICATE_URL || '';
const companyImage = process.env.NEXT_PUBLIC_IMAGE_URL_PREFIX || '';
const accountUrl = process.env.NEXT_PUBLIC_ACCOUNT_URL || '';
const razorPayKey = process.env.NEXT_PUBLIC_RAZORPAY_KEY || '';
const recaptchaKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY || '';
const environment = process.env.NEXT_PUBLIC_ENVIRONMENT || '';
const googleClientId = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || '';
const courseThumbnail = '';
const penPencilClientId = process.env.NEXT_PUBLIC_PEN_PENCIL_CLIENT_ID || '';
const penPencilClientType = process.env.NEXT_PUBLIC_PEN_PENCIL_CLIENT_TYPE || '';
const penPencilApiUrl = process.env.NEXT_PUBLIC_PEN_PENCIL_API_URL || '';
const penPencilTokenGrantType = process.env.NEXT_PUBLIC_PEN_PENCIL_TOKEN_GRANT_TYPE;
const penPencilTokenClientId = process.env.NEXT_PUBLIC_PEN_PENCIL_TOKEN_CLIENT_ID;
const penPencilTokenClientSecret = process.env.NEXT_PUBLIC_PEN_PENCIL_TOKEN_CLIENT_SECRET;
const domainUrl = process.env.NEXT_PUBLIC_DOMAIN_URL;
const kapturCustomerApiFlag = process.env.NEXT_PUBLIC_KAPTURE_CUSTOMER_API;
const kaptureChatbotScript = process.env.NEXT_PUBLIC_KAPTURE_CHATBOT_SCRIPT;
const kaptureDataSupportKey = process.env.NEXT_PUBLIC_KAPTURE_DATA_SUPPORT_KEY;
const kaptureBaseAPIUrl = process.env.NEXT_PUBLIC_KAPTURE_BASE_API_URL;
const zoomSdkKey = process.env.NEXT_PUBLIC_ZOOM_SDK_KEY;
const sentryAuthToken = process.env.SENTRY_AUTH_TOKEN || '';

export {
  googleClientId,
  baseApiUrl,
  s3Url,
  certificateUrl,
  companyImage,
  accountUrl,
  razorPayKey,
  recaptchaKey,
  environment,
  courseThumbnail,
  penPencilClientId,
  penPencilClientType,
  penPencilApiUrl,
  penPencilTokenGrantType,
  penPencilTokenClientId,
  domainUrl,
  penPencilTokenClientSecret,
  kapturCustomerApiFlag,
  kaptureChatbotScript,
  kaptureDataSupportKey,
  kaptureBaseAPIUrl,
  zoomSdkKey,
  sentryAuthToken,
};
