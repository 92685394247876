import { create } from 'zustand';

type MiscellaneousType = {
  currentLessonName: string;
  setCurrentLessonName: (data: string) => void;
  codeSubmisisonMap: any;
  setCodeSubmissionMap: (data: any) => void;
  codeSolutionsMap: any;
  setCodeSolutionsMap: (data: any) => void;
  isDeviceIOS: boolean;
  setIsDeviceIOS: (data: string) => void;
  chatBotType: string;
  setChatbotType: (data: string) => void;
};

const useMiscellaneousStore = create<MiscellaneousType>((set) => ({
  currentLessonName: '',
  setCurrentLessonName: (data: string) => {
    set(() => ({ currentLessonName: data }));
  },
  codeSubmisisonMap: {},
  setCodeSubmissionMap: (data: any) => {
    set(() => ({ codeSubmisisonMap: data }));
  },
  codeSolutionsMap: {},
  setCodeSolutionsMap: (data: any) => {
    set(() => ({ codeSolutionsMap: data }));
  },
  isDeviceIOS: false, // used to disable document?.documentElement?.requestFullscreen()  , as it is not supported for iOS.
  setIsDeviceIOS: (data: string) => {
    set(() => ({ isDeviceIOS: /iPhone/.test(data) }));
  },
  chatBotType: '',
  setChatbotType: (data: string) => {
    set(() => ({ chatBotType: data }));
  },
}));

export default useMiscellaneousStore;
