import { baseApiUrl } from '@/utils/common/env.constants';
import { IAllCourses, ISitemapCourseAndCategories, IUserCountry } from '@/interfaces/common';
import { get } from './index';

class Common {
  static async getCountry() {
    return get<IUserCountry>('/country', undefined, `${baseApiUrl}/v1`);
  }

  static async getAllCourses() {
    return get<IAllCourses>('/course', undefined, `${baseApiUrl}/v1`);
  }

  static async getSitemapCourseAndCategories() {
    return get<ISitemapCourseAndCategories>('/sitemap', undefined, `${baseApiUrl}/v2`);
  }
}

export default Common;
