import { create } from 'zustand';

export type UsersingleCourseDetails = {
  courseId: string;
  recentlyAccessedLessons: { lessonId: string }[];
  parentCourse?: { _id: string; slug: string };
  courseSlug?: string;
  courseType?: string;
};

export type UserCourseDetails = {
  userCourses: { courseId: string; recentlyAccessedLessons: { lessonId: string }[] }[];
  setUserCourses: (progress: []) => void;
  userCoursesMap: any;
  setUserCoursesMap: (data: any) => void;
  planCourseMap: any;
  setPlanCourseMap: (data: any) => void;
};

const useUserCourseDetails = create<UserCourseDetails>((set) => ({
  userCourses: [],
  userCoursesMap: {},
  planCourseMap: {},
  setUserCourses: (data) => {
    set({ userCourses: data });
  },
  setUserCoursesMap: (data: any) => {
    set({ userCoursesMap: data });
  },
  setPlanCourseMap: (data: any) => {
    set({ planCourseMap: data });
  },
}));

export default useUserCourseDetails;
