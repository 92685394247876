import {
  ICentralVideoPlayerWatchStats,
  ISkillsWatchStats,
  VideoSession,
} from '@/interfaces/lectureTrack';
import { ACCESS_TOKEN, PP_TOKEN } from '@/utils/common/common.constants';
// import uuid from 'react-uuid';

export const getAccessToken = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return localStorage.getItem(PP_TOKEN);
};

export const getSkillsAccessToken = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }
  return localStorage.getItem(ACCESS_TOKEN);
};

export const getRandomId = () => {
  const randomId = localStorage.getItem('randomUid');
  // if (randomId === null) {
  //   randomId = uuid();
  //   localStorage.setItem('randomId', randomId);
  // }
  return randomId;
};

export const sendCommandsToPlayer = (data: any) => {
  const videoPlayerContent = document.getElementById('pw-video-player') as HTMLIFrameElement | null;
  if (videoPlayerContent && videoPlayerContent.contentWindow) {
    videoPlayerContent.contentWindow.postMessage(data, '*');
  }
};
export const noCookieDomains = ['local', '402', 'ultron'];

export const convertToSeconds = (inputTime: string) => {
  const timeArray = inputTime?.split(':');
  const totalTimeInSeconds =
    Number(timeArray[0]) * 3600 + Number(timeArray[1]) * 60 + Number(timeArray[2]);
  return totalTimeInSeconds;
};
export const calculateInterval = (videoDuration: string) => {
  // here time interval is calculated wrt video duration keeping limited api calls
  const timeInSeconds = convertToSeconds(videoDuration);
  // less than 5 min
  if (timeInSeconds < 300) return 60000;
  // 5 < timeInSeconds <= 1hr
  if (timeInSeconds >= 300 && timeInSeconds <= 3600) return 120000;
  // 1hr < timeInSeconds <= 3hr
  if (timeInSeconds > 3600 && timeInSeconds <= 7200) return 240000;
  return 360000;
};
export const formatWatchStatsData = (
  centralWatchStats: ICentralVideoPlayerWatchStats[],
  shouldPassTimeStamps: boolean
) => {
  const tempArray: ISkillsWatchStats[] = [];
  centralWatchStats?.forEach((watchStatsObj) => {
    let tempObj: ICentralVideoPlayerWatchStats | ISkillsWatchStats = {
      ...watchStatsObj,
    };
    const currentTimeStamp = new Date().getTime(); // should be passed only for 2min interval api call as update api called on unmounting has total duration
    tempObj = {
      startTime: watchStatsObj?.startTime,
      endTime: watchStatsObj?.endTime,
      endTimeStamp: shouldPassTimeStamps ? watchStatsObj?.endTimeTimestamp : currentTimeStamp,
      startTimeStamp: shouldPassTimeStamps ? watchStatsObj?.startTimeTimestamp : currentTimeStamp,
    };
    tempArray.push(tempObj);
  });
  return tempArray;
};
export const returnFormattedPayload = (item: VideoSession, shouldPassTimeStamps: boolean) => {
  const centralWatchStats = JSON.parse(localStorage.getItem('watchStats') as string);
  const formattedVideoSession = {
    ...item,
    session: {
      ...item?.session,
      watchStats: formatWatchStatsData(centralWatchStats, shouldPassTimeStamps),
    },
  };
  return formattedVideoSession;
};
