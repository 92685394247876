import { IUserProfileDetails } from '@/interfaces/common';
import { create } from 'zustand';

type UserDetailsType = {
  userIsLoggedIn: boolean;
  setUserIsLoggedIn: (data: boolean) => void;
  userDetails: IUserProfileDetails;
  setUserDetails: (data: IUserProfileDetails) => void;
  profileLoader: boolean;
  setProfileLoader: (data: boolean) => void;
};

const useUserDetails = create<UserDetailsType>((set) => ({
  userIsLoggedIn: false,
  setUserIsLoggedIn: (data: boolean) => {
    set(() => ({ userIsLoggedIn: data }));
  },
  userDetails: {
    _id: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    isdCode: '',
    email: '',
    verified: false,
    createdAt: '',
    img: { link: '', source: '' },
  },
  setUserDetails: (data: IUserProfileDetails) => {
    set(() => ({ userDetails: data }));
  },
  profileLoader: true,
  setProfileLoader: (data: boolean) => {
    set(() => ({ profileLoader: data }));
  },
}));

export default useUserDetails;
