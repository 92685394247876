/* eslint-disable @typescript-eslint/indent */
import React, { createContext, useMemo, useState } from 'react';
import { useScreenSize } from '@pwskills/rachnaui/hooks';
import {
  EmiOptionsResponseType,
  IOrderDetails,
  RazorpayDowntime,
  RazorpayMethods,
} from '@/interfaces/checkout';
import { getCountryFromCache } from '@/utils/helper/helperFunction';

type PricingObj = {
  IN: number;
  US: number;
  discount: number;
  couponDiscount?: number;
  isFree: boolean;
};

interface CourseCategoryDetails {
  _id: string;
  title: string;
  parent: string;
  slug: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
}
interface CategoryDetails {
  _id: string;
  title: string;
  slug: string;
}

type CourseObj = {
  description: string;
  title: string;
  img: string;
  mode: string;
  slug?: string;
  programType?: string[];
  categoryDetails?: CourseCategoryDetails;
  parentCategoryDetails?: CategoryDetails;
};

type ParentCourseObj = {
  slug?: string;
  _id?: string;
};

type CheckoutContextType = {
  currentCountry: string;
  renderEmiCard: boolean;
  setRenderEmiCard: React.Dispatch<React.SetStateAction<boolean>>;
  emiDuration: string;
  setEmiDuration: React.Dispatch<React.SetStateAction<string>>;
  paymentMethods: RazorpayMethods;
  setPaymentMethods: React.Dispatch<React.SetStateAction<RazorpayMethods>>;
  paymentDowntime: RazorpayDowntime;
  setPaymentDowntime: React.Dispatch<React.SetStateAction<RazorpayDowntime>>;
  isDesktop: boolean;
  razorpay: any;
  setRazorpay: React.Dispatch<React.SetStateAction<any>>;
  showMobileEmiDetails: boolean;
  setShowMobileEmiDetails: React.Dispatch<React.SetStateAction<boolean>>;
  activeCardlessEmi: boolean;
  setActiveCardlessEmi: React.Dispatch<React.SetStateAction<boolean>>;
  mobileEmiDetails: EmiOptionsResponseType[];
  setMobileEmiDetails: React.Dispatch<React.SetStateAction<EmiOptionsResponseType[]>>;
  showMobileBackButton: boolean;
  setShowMobileBackButton: React.Dispatch<React.SetStateAction<boolean>>;
  orderDetails: IOrderDetails;
  setOrderDetails: React.Dispatch<React.SetStateAction<IOrderDetails>>;
  courseDetails: CourseObj;
  setCourseDetails: React.Dispatch<React.SetStateAction<CourseObj>>;
  pricing: PricingObj;
  setPricing: React.Dispatch<React.SetStateAction<PricingObj>>;
  planDetails: any;
  setPlanDetails: React.Dispatch<React.SetStateAction<any>>;
  couponCode: string;
  setCouponCode: React.Dispatch<React.SetStateAction<string>>;
  couponLoading: boolean;
  setCouponLoading: React.Dispatch<React.SetStateAction<boolean>>;
  applyCouponCodeSuccess: boolean;
  setApplyCouponCodeSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  failedMessage: string;
  setFailedMessage: React.Dispatch<React.SetStateAction<string>>;
  transactionId: string | null;
  setTransactionId: React.Dispatch<React.SetStateAction<string | null>>;
  parentCourseDetails: ParentCourseObj;
  setParentCourseDetails: React.Dispatch<React.SetStateAction<ParentCourseObj>>;
};

const CheckoutContextState = {
  currentCountry: 'IN',
  renderEmiCard: false,
  setRenderEmiCard: () => null,
  emiDuration: '',
  setEmiDuration: () => null,
  paymentMethods: {
    card_networks: {},
    cardless_emi: {},
    debit_emi_providers: {},
    emi_options: {},
    emi_types: { credit: false, debit: false },
    emi_plans: {},
    netbanking: {},
    paylater: {},
    wallet: {},
  },
  setPaymentMethods: () => null,
  paymentDowntime: {
    count: 0,
    items: [],
  },
  setPaymentDowntime: () => null,
  isDesktop: true,
  razorpay: {},
  setRazorpay: () => null,
  showMobileEmiDetails: false,
  setShowMobileEmiDetails: () => null,
  mobileEmiDetails: [],
  setMobileEmiDetails: () => null,
  activeCardlessEmi: false,
  setActiveCardlessEmi: () => null,
  showMobileBackButton: false,
  setShowMobileBackButton: () => null,
  orderDetails: {
    amount: 0,
    checkoutType: '',
    clientSecret: '',
    courseId: '',
    currency: '',
    gateway: '',
    orderId: '',
    originalAmountWithoutDiscount: 0,
    paid: false,
    userBrowserDetails: { device: '', client: '', version: '' },
    userId: '',
  },
  setOrderDetails: () => null,
  courseDetails: {
    description: '',
    title: '',
    img: '',
    mode: '',
    slug: '',
    programType: [],
  },
  setCourseDetails: () => null,
  pricing: {
    IN: 0,
    US: 0,
    discount: 0,
    isFree: false,
  },
  setPricing: () => null,
  planDetails: {
    name: '',
  },
  setPlanDetails: () => null,
  couponCode: '',
  setCouponCode: () => null,
  couponLoading: false,
  setCouponLoading: () => null,
  applyCouponCodeSuccess: false,
  setApplyCouponCodeSuccess: () => null,
  failedMessage: '',
  setFailedMessage: () => null,
  transactionId: null,
  setTransactionId: () => null,
  parentCourseDetails: {
    slug: '',
  },
  setParentCourseDetails: () => null,
};

export const CheckoutContext = createContext<CheckoutContextType>(CheckoutContextState);

const CheckoutContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentCountry] = useState<CheckoutContextType['currentCountry']>(getCountryFromCache());
  const [paymentMethods, setPaymentMethods] = useState<CheckoutContextType['paymentMethods']>({
    card_networks: {},
    cardless_emi: {},
    debit_emi_providers: {},
    emi_options: {},
    emi_types: { credit: false, debit: false },
    emi_plans: {},
    netbanking: {},
    paylater: {},
    wallet: {},
  });
  const [paymentDowntime, setPaymentDowntime] = useState<CheckoutContextType['paymentDowntime']>({
    count: 0,
    items: [],
  });
  const [razorpay, setRazorpay] = useState<CheckoutContextType['razorpay']>({});
  const [renderEmiCard, setRenderEmiCard] = useState<CheckoutContextType['renderEmiCard']>(false);
  const [emiDuration, setEmiDuration] = useState<CheckoutContextType['emiDuration']>('');
  const [showMobileEmiDetails, setShowMobileEmiDetails] =
    useState<CheckoutContextType['showMobileEmiDetails']>(false);
  const [mobileEmiDetails, setMobileEmiDetails] = useState<CheckoutContextType['mobileEmiDetails']>(
    []
  );
  const [activeCardlessEmi, setActiveCardlessEmi] =
    useState<CheckoutContextType['activeCardlessEmi']>(false);
  const [showMobileBackButton, setShowMobileBackButton] =
    useState<CheckoutContextType['showMobileBackButton']>(false);
  const [courseDetails, setCourseDetails] = useState<CheckoutContextType['courseDetails']>({
    description: '',
    title: '',
    img: '',
    mode: '',
  });
  const [parentCourseDetails, setParentCourseDetails] = useState<
    CheckoutContextType['parentCourseDetails']
  >({
    slug: '',
    _id: '',
  });
  const [pricing, setPricing] = useState<CheckoutContextType['pricing']>({
    IN: 0,
    US: 0,
    discount: 0,
    isFree: false,
  });
  const [planDetails, setPlanDetails] = useState<CheckoutContextType['planDetails']>({
    _id: '',
    name: '',
  });
  const [couponCode, setCouponCode] = useState<CheckoutContextType['couponCode']>('');
  const [couponLoading, setCouponLoading] = useState<boolean>(false);
  const [applyCouponCodeSuccess, setApplyCouponCodeSuccess] =
    useState<CheckoutContextType['applyCouponCodeSuccess']>(false);
  const [failedMessage, setFailedMessage] = useState<CheckoutContextType['failedMessage']>('');
  const [transactionId, setTransactionId] = useState<CheckoutContextType['transactionId']>(null);
  const [orderDetails, setOrderDetails] = useState<CheckoutContextType['orderDetails']>({
    amount: 0,
    checkoutType: '',
    clientSecret: '',
    courseId: '',
    currency: '',
    gateway: '',
    orderId: '',
    originalAmountWithoutDiscount: 0,
    paid: false,
    userBrowserDetails: { device: '', client: '', version: '' },
    userId: '',
  });
  const size = useScreenSize();

  const isDesktop = useMemo(() => {
    if (size.width > 1200) {
      return true;
    }
    return false;
  }, [size]);

  const CheckoutStore = useMemo(() => {
    return {
      currentCountry,
      renderEmiCard,
      setRenderEmiCard,
      emiDuration,
      setEmiDuration,
      paymentMethods,
      setPaymentMethods,
      paymentDowntime,
      setPaymentDowntime,
      isDesktop,
      razorpay,
      setRazorpay,
      showMobileEmiDetails,
      setShowMobileEmiDetails,
      mobileEmiDetails,
      setMobileEmiDetails,
      activeCardlessEmi,
      setActiveCardlessEmi,
      showMobileBackButton,
      setShowMobileBackButton,
      orderDetails,
      setOrderDetails,
      courseDetails,
      setCourseDetails,
      pricing,
      planDetails,
      setPlanDetails,
      setPricing,
      couponCode,
      setCouponCode,
      couponLoading,
      setCouponLoading,
      applyCouponCodeSuccess,
      setApplyCouponCodeSuccess,
      failedMessage,
      setFailedMessage,
      transactionId,
      setTransactionId,
      parentCourseDetails,
      setParentCourseDetails,
    };
  }, [
    currentCountry,
    renderEmiCard,
    emiDuration,
    paymentMethods,
    paymentDowntime,
    isDesktop,
    razorpay,
    showMobileEmiDetails,
    mobileEmiDetails,
    activeCardlessEmi,
    showMobileBackButton,
    courseDetails,
    orderDetails,
    pricing,
    planDetails,
    setPlanDetails,
    couponCode,
    couponLoading,
    applyCouponCodeSuccess,
    failedMessage,
    transactionId,
    parentCourseDetails,
  ]);

  return <CheckoutContext.Provider value={CheckoutStore}>{children}</CheckoutContext.Provider>;
};

export default CheckoutContextProvider;
