import { IUserDetails, IVerifyToken } from '@/interfaces/common';
import { baseApiUrl } from '@/utils/common/env.constants';
import { get, post } from './index';

class AuthApi {
  static async verifyCode(code: string) {
    return post<IVerifyToken>(`/auth/token/verify/${code}`, code, undefined, `${baseApiUrl}/v1`);
  }

  static async getUserProfile() {
    return get<IUserDetails>('/auth/profile', undefined, `${baseApiUrl}/v1`);
  }
}

export default AuthApi;
