import { baseApiUrl } from '@/utils/common/env.constants';
import { get } from './index';

class userCoursesApi {
  static async getUserCourses() {
    return get<{ courseId: string }>(
      '/v2/enrol/list?skip=0&limit=100&select=courseId,recentlyAccessedLessons',
      undefined,
      `${baseApiUrl}`
    );
  }
}

export default userCoursesApi;
