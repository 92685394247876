import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import { useUTMParameters } from '@pwskills/rachnaui/hooks';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import SwrConfigProvider from '@/context/SwrConfigProvider';
// import { UserProvider } from '@/context/UserDetailsContext';
import CheckoutContextProvider from '@/context/CheckoutContextProvider';
import useCouponCode from '@/hooks/useCouponCode';
import { environment, recaptchaKey } from '@/utils/common/env.constants';
import Common from '@/api/common';
import '@/styles/globals.css';
import '@/styles/ZoomModalStyles.css';
import '@/styles/CkEditorStyles.css';
import '@/styles/ReactPlayerStyles.css';
import '@/styles/masterclassDescriptionPage.css';
import '@pwskills/rachnaui/index.css';
import 'react-toastify/dist/ReactToastify.css';
// import useInitLogin from '@/hooks/useInitLogin';
// import { checkoutContextRoutes, tawkPageRoutes, PP_TOKEN } from '@/utils/common/common.constants';
import { checkoutContextRoutes, PP_TOKEN } from '@/utils/common/common.constants';
import { dataLayer } from '@/utils/common/dataLayer.utils';
import { getCookies, hideorShowReveChat } from '@/utils/helper/helperFunction';
import { noCookieDomains } from '@/components/common/video/utils';
import useMiscellaneousStore from '@/store/useMiscellaneousStore';
import SKillsBot from '@/components/SkillsBot/SKillsBot';
import useUserDetails from '@/store/userDetails';
import useGetUserProfileDetails from '@/hooks/useGetUserProfileDetails';
import Spinner from '@/components/common/Loader';

declare global {
  interface Window {
    Tawk_API: any;
  }
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const utmQuery = useUTMParameters();
  const { setIsDeviceIOS } = useMiscellaneousStore();
  const { userIsLoggedIn } = useUserDetails();
  useCouponCode();
  let userLocation = 'IN';
  // const { isLoading, isLoggedIn } = useContext(UserDetailsContext);
  // const [tawkLoaded, setTawkLoaded] = useState<boolean>(false);
  // const { user } = useInitLogin();
  const { userDetails: user, profileLoader } = useUserDetails();
  const { getUserDetails } = useGetUserProfileDetails();
  const getYourCountryDetails = async () => {
    try {
      const response = await Common.getCountry();
      if (response) {
        userLocation = response?.data;
        sessionStorage.setItem('USER_COUNTRY', `${response?.data}`);
      }
    } catch (error) {
      userLocation = 'IN';
      sessionStorage.setItem('USER_COUNTRY', `IN`);
    }
  };
  useEffect(() => {
    if (router.isReady) {
      getYourCountryDetails();
      setIsDeviceIOS(navigator.userAgent);
    }
    // window.Tawk_API.onLoad = () => {
    //   setTawkLoaded(true);
    //   if (tawkPageRoutes?.includes(router.pathname)) {
    //     window.Tawk_API.showWidget();
    //   } else {
    //     window.Tawk_API.hideWidget();
    //   }
    // };
    // if (tawkLoaded && tawkPageRoutes?.includes(router.pathname)) {
    //   window.Tawk_API.showWidget();
    // }
    // if (tawkLoaded && !tawkPageRoutes?.includes(router.pathname)) {
    //   window.Tawk_API.hideWidget();
    // }

    // eslint-disable-next-line no-console
    if (
      noCookieDomains.includes(environment) &&
      router.query.ppToken &&
      router.query.ppToken?.length > 0
    )
      localStorage.setItem(PP_TOKEN, router.query?.ppToken as string);
    else localStorage.setItem(PP_TOKEN, getCookies('ppToken') as string);
    if (
      noCookieDomains.includes(environment) &&
      router.query.randomId &&
      router.query.randomId?.length > 0
    )
      localStorage.setItem('randomUid', router.query?.randomId as string);
    else localStorage.setItem('randomUid', getCookies('randomUid') as string);
  }, [router]);

  const userDataLayerEvent = () => {
    const userDataLayerObject = {
      event: 'user_details',
      user_type: userIsLoggedIn ? 'logged_in' : 'guest',
      user_name: userIsLoggedIn ? `${user.firstName} ${user.lastName || ''}` : null,
      email: userIsLoggedIn ? user.email : null,
      number: userIsLoggedIn ? `${user.isdCode}${user.phoneNumber || ''}` : null,
      country:
        (typeof window !== 'undefined' && window.sessionStorage.getItem('USER_COUNTRY')) ||
        userLocation,
      user_category: null,
      user_id: userIsLoggedIn ? user._id : null,
      gclid: null,
      utm_source: null,
      utm_medium: null,
      utm_campaign: null,
      ...utmQuery,
    };
    dataLayer(userDataLayerObject);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && userIsLoggedIn) {
      userDataLayerEvent();
    }
  }, [profileLoader, userIsLoggedIn]);

  useEffect(() => {
    if (router?.isReady) {
      getUserDetails(false);
      if (!router?.asPath?.includes('/learn/course')) {
        hideorShowReveChat(false);
      }
    }
  }, [router?.isReady]);

  if (profileLoader)
    return (
      <div className="flex items-center justify-center h-screen w-full">
        <Spinner />
      </div>
    );
  return (
    <>
      <SwrConfigProvider>
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
          {checkoutContextRoutes.includes(router.pathname) ? (
            <CheckoutContextProvider>
              <Component {...pageProps} />
            </CheckoutContextProvider>
          ) : (
            <Component {...pageProps} />
          )}
        </GoogleReCaptchaProvider>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="light"
        />
      </SwrConfigProvider>
      <SKillsBot />
    </>
  );
};

export default App;
